import React from 'react';
import {isDesktop} from "@/Helpers/Function";
import Svg from "@/Pages/All/Svg";
import LinkLoad from "@/Layouts/LinkLoad";

export default function Logo() {
    return <LinkLoad href={`/`}
                        aria-label="Главная"
                        className={`headerBox__logo`}>
        <Svg id={isDesktop() ? 'main-logo-new' : 'main-logo-mobile-new'}
             className={isDesktop() ? 'svg__main-logo' : 'svg__main-logo-mobile'}/>
    </LinkLoad>
}
